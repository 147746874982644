<template>
  <div class="video-play">
    <video ref="video" :src="videoSrc"
           @contextmenu.prevent
           :poster="videoSrc + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'"
           autoplay muted loop playsinline preload="auto"
           x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"></video>
    <div class="video-control">
      <img @click="unmute" v-show="isMuted" class="mute-status" alt :src='require("@/assets/img/video-play/video-muted.png")'/>
      <img @click="mute" v-show="!isMuted" class="mute-status" alt :src='require("@/assets/img/video-play/video-unmuted.png")'/>
      <img @click="pause" v-show="isPlaying" class="play-status" alt :src='require("@/assets/img/video-play/video-playing.png")'/>
      <img @click="play" v-show="!isPlaying" class="play-status" alt :src='require("@/assets/img/video-play/video-paused.png")'/>
    </div>
  </div>
</template>

<script>
import Popup from '../../popup/src/popup';
export default {
  name: 'VideoPlay',
  components: {Popup},
  props: {
    videoSrc: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPlaying: true,
      isMuted: true,
    }
  },
  methods: {
    play() {
      this.$refs.video.play();
      this.isPlaying = true;
    },
    pause() {
      this.$refs.video.pause();
      this.isPlaying = false;
    },
    mute() {
      this.$refs.video.muted = true;
      this.isMuted = true;
    },
    unmute() {
      this.$refs.video.muted = false;
      this.isMuted = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-play {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  >video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  >.video-control {
    position: absolute;
    right: 0.9rem;
    bottom: 1rem;
    display: flex;
    align-items: center;

    >.mute-status {
      cursor: pointer;
      width: 0.24rem;
      margin-right: 0.24rem;
      display: none;
    }

    >.play-status {
      cursor: pointer;
      width: 0.44rem;
    }
  }
}
</style>
